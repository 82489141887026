import React, {useState, useEffect, useContext} from "react"
import { Row, Col, Badge, Alert, Button } from 'react-bootstrap'
import {
    fetching,
    useAlert,
    post,
    get,
    sendTrack
} from "../Generals"

import DateFormatted from '../Components/DateFormatted'
import Loader from '../Components/Loader'
import noimg from './../no-img.jpg'
import {SendOutline, ChevronDownCircle} from 'react-ionicons'

import bgimg from './../ingles-en-serio-madera6859-optimized.jpg'
import GeneralContext from "../utils/generalContext";
import { useParams } from "react-router"

const Itemchat = (props) => {
    let content=props.content
    let global=props.global
    let field='nombre'

    if(props.type === 1){
        field='Nombre'
    }

    let img = noimg
    if(content.img !== null ){
        img=global.domain+content.img
    }
    return <div key={'contentitem'+props.type+content.id} className={'chat-item'}>
        <div className="avatar avatar-online "><span className="avatar-initial bgimgh rounded-circle" style={{backgroundImage:'url('+img+')'}}></span></div>
        <div className="chat-item-body"><div><span>{content[field]}</span></div></div>
    </div>
}

const Startgroup = (props) => {
        let {content,salirGrupo,global,id}=props
        let [link,setLink] = useState(content.grupoconversacion.joinurl)
        let img = content.grupoconversacion.cursos_grupos_conversaciones_tema.img
        if(img === null || img === ""){
            img=noimg
        }else{
            img=global.domain+img
        }

        const checkLink = () => {
            let params = {
                id:id
            }
            let url=global.domain+"api/v1/checkiLinkGrupo/"+global.apicode+'/'
            fetching(url,params,setLink,null)
        }

        useEffect(()=>{
            const newWindow = window.open(link, "_blank");
            const interval = setInterval( () => {
                checkLink()
            }, 8000)
            return () => clearInterval(interval)
        },[])


        return <div>
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-7">
                            <div className="card card-people-two">
                                <div className="card-header">
                                    <div className="pos-absolute a-0">
                                        <img src={bgimg} alt="" />
                                    </div>
                                    <div className="avatar avatar-md">
                                        <div className="bgimgh rounded-circle"
                                             style={{widht:'84px',height:'84px',backgroundImage:'url('+img+')'}}></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <p className="text-muted">Ha comenzado el grupo de conversación:</p>
                                    <h5 className="card-title mb-0">{content.grupoconversacion.nombre}</h5>
                                    <p className="mb-3">{content.grupoconversacion.cursos_curso.nombre}</p>
                                    <p>Para entrar, clica en el siguiente botón. Dale a zoom, permitir.</p>
                                    <a className="btn btn-lg my-3 btn-success"
                                         id="linktojoin"
                                         target="_blank"
                                         rel="noreferrer"
                                         href={link}>Entrar</a>
                                </div>
                                <div className="card-footer text-center bg-light pt-3">
                                    <h5 className="font-weight-light mb-0">Quiere salir del grupo? <button
                                        onClick={() => salirGrupo()}
                                        className="link link-danger">Salir
                                        del grupo aquí.</button></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    }


const Chatstructure = (props) => {
    let { id, userid, content, user, data, salirGrupo, setContent, global } = props
    const charactersavailable = 100

    const [responseDataChat, setResponseDataChat] = useState();
    const [variant,setVariant] = useState('success')
    const element = document.getElementById('chat-body-content')
    const [messages, setMessages] = useState([])
    const [lastmsgid, setLastMsgId] = useState(null)
    const [chatVal,setChatVal] = useState("")
    const [countcharactersavailable,setCountcharactersavailable] = useState(charactersavailable)
    const [displayGoBottom,setdisplayGoBottom] = useState(false)
    
    const submitMsg = () =>{
        setChatVal("")
        setCountcharactersavailable(charactersavailable)
        document.getElementById('inputsubmit').value = ""
        
        let params = {
            id:id,
            userid:userid,
            type: 1,
            msg:chatVal
        }
        let url=global.domain+"api/v1/submitmessagesgruposdeconversacionV3/"+global.apicode+'/'
        fetching(url,params,null,null)
    }

    const goBottom = () =>{
        let heightChat = element.offsetHeight
        let scrollHeight = element.scrollHeight
        let actualheight = scrollHeight-heightChat
        setdisplayGoBottom(false)
        element.scrollTop = actualheight
    }

    const setNewVal = (value) => {
        let leng=value.length
        leng=charactersavailable - leng

        if(leng < (charactersavailable/2)){
            setVariant('warning')
        } else if(leng <= 1){
            setVariant('danger')
        } else if(leng > (charactersavailable/2)){
            setVariant('success')
        }
        setCountcharactersavailable(leng)
    }

    useEffect( () => {
        if(responseDataChat){
            setLastMsgId(responseDataChat.mensajes.lastmsgid)
            let arr = responseDataChat.mensajes.mensajes.map(item => item)
            setMessages(arr)
            if(element !== null){
                let heightChat = element.offsetHeight
                let scrollHeight = element.scrollHeight
                let actualheight = scrollHeight-heightChat
                let scrollTop = element.scrollTop
                if(scrollTop < actualheight && content.mensajes.mensajescount > 0){
                    setdisplayGoBottom(true)
                }
            }
            setContent(responseDataChat)
        }
    }, [responseDataChat])

    const refreshGrupo = () =>{
        const body = {
            id: id,
            user_id: userid,
            type: 1,
            last_msg_id: lastmsgid
        }

        get(global.domain+"v3-api/v3/get_data_grupo_conversacion.json", body, setResponseDataChat);
    }

    useEffect(()=>{
        const interval = setInterval(()=>{
            refreshGrupo()
        }, 1500)
        return()=>clearInterval(interval)
    },[])

    return <div>
        {content === null ? <Loader/> :
            <div>
                <Row className={'chat-panel'}>
                    <Col md={3} lg={3}>
                        <div className={'chat-sidebar'}>
                            <div className="chat-sidebar-header">
                                <h6 className="p-2 mb-0">Quienes están en el grupo.</h6>
                                <Badge>BETA</Badge>
                            </div>
                            <div className={'chat-sidebar-body'}>
                                <ul className="chat-list spacechat">
                                    <div className="spaceteachers">
                                        {content.profesoresconectados.map((profesor,index)=><Itemchat key={'itemchat'+index} global={global} type={2} content={profesor.cursos_profesore}/>)}
                                    </div>
                                    <div className="spacestudent">
                                        {content.estudiantesconectados.map((estudiante,index)=><Itemchat key={'itemchatpro'+index} global={global} type={1} content={estudiante.user}/>)}
                                    </div>
                                </ul>
                            </div>
                            <div className={'chat-sidebar-footer'}>
                                <h6 className="chat-loggeduser">{user.user.user.Nombre} <span>(Yo)</span></h6>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'chat-body'}>
                            <div className={'chat-body-header'}>
                                <div className="chat-body-header">
                                    <h6 className="font-weight-light mb-0">Sala de espera grupo de conversación <strong>{data.grupo.name}</strong>.</h6>
                                    <div className="chat-body-options">
                                        <Button variant={'light'} onClick={()=>salirGrupo()} className="btn btn-outline-light">Salir</Button>
                                    </div>
                                </div>
                            </div>
                            <div id={'chat-body-content'} className={'chat-body-content ps ps--active-y'}>
                                {messages.length > 0 ? <ul className={'chat-msg-list loadchatspace'}>
                                    {messages.map(item=>{
                                            let name
                                            let img
                                            let classnname=''
                                            let date=''
                                            if(item.type !== 0 && item.user !== null){
                                                name=item.user.Nombre
                                                img=item.user.img
                                                if( item.type === 1 && item.userid === userid){
                                                    classnname='reverse'
                                                }
                                            }else if(item.type !== 0 && item.cursos_profesore !== null){
                                                name=item.cursos_profesore.nombre
                                                img=item.cursos_profesore.img
                                                // if( item.type === 2 && item.userid === userid){
                                                //     classnname='reverse'
                                                // }
                                            }
                                            if(img === null){
                                                img = noimg
                                            }else{
                                                img = global.domain+img
                                            }

                                            return <>
                                                {item.type !== 0 ? <li className={'msg-item '+classnname}>
                                                    <div className="avatar avatar-sm"><span
                                                        className="avatar-initial bgimgh rounded-circle"
                                                        style={{backgroundImage:'url('+img+')'}}></span>
                                                    </div>
                                                    <div className="msg-body">
                                                        <h6 className="msg-user">{name}<span>{date}</span></h6>
                                                        <p><span>{item.mensaje}</span></p>
                                                    </div>
                                                </li> : <li className="divider-text">{item.mensaje}</li>}
                                            </>
                                        }
                                    )}
                                </ul>:null}
                            </div>
                            <div className={'position-relative'}>
                                {displayGoBottom ? <Button className={'downbutton'} variant={'danger'} style={{position:'absolute',bottom:'55px', right:'5px'}} onClick={()=>goBottom()}><ChevronDownCircle width={'23px'} shake height={'23px'} color={'#fff'}/></Button> : null}
                                <div className={'chat-body-footer'}>
                                    <div className="chat-body-options">
                                    </div>
                                    <div className="form-group">
                                        <input id="inputsubmit" 
                                               
                                               type="text"
                                               defaultValue={chatVal}
                                               onKeyUp={(e) => {
                                                        if (e.code === 'Enter') {
                                                            submitMsg()
                                                        }else{
                                                            setChatVal(e.target.value)
                                                        }
                                                    }
                                                }
                                               className="form-control inputsubmit"
                                               maxLength={charactersavailable}
                                               placeholder="Escribe algo..." />
                                    </div>
                                    <Badge bg={variant}>{countcharactersavailable}</Badge>
                                    <button className="btn btn-icon sendmessage" onClick={()=>submitMsg()}><SendOutline height="20px" width="20px" /></button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} lg={4}>
                        <Cardgroup global={global} data={data}/>
                    </Col>
                </Row>
            </div>
        }</div>
    }

const Cardgroup = (props) =>{
    let {global,data} = props
    return <div className="card card-hover card-profile-one">
        <div className="row no-gutters">
            <div className="col-md">
                <div className="relative bgimgh"
                     style={{backgroundImage:'url('+global.domain+data.tema.img+')', overflow: 'hidden', height: '100%'}}>
                </div>
            </div>
            <div className="col-md-7">
                <div className="card-body px-3">
                    <div className="media">
                        <div className="">
                            <h5 className="card-title">{data.grupo.nombre} - <small
                                className="text-muted">{data.tema.nombre}</small></h5>
                            {data.grupo.descripciontranslate !== null && data.grupo.descripciontranslate !=="" ? <p className="card-desc" dangerouslySetInnerHTML={{__html:data.grupo.descripciontranslate}}></p> : null }
                            <div className="media-footer">
                                <div style={{marginRight:10}}>
                                    <h6>{data.grupo.countmembersactive}/{data.grupo.limite}</h6>
                                    <label>Unidos</label>
                                </div>
                                <div><h6><DateFormatted value={data.grupo.date} /></h6>
                                    <label>Hora Programada</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}
export const Chatgrupo = () => {
        const { id } = useParams();
        const [ , setAlert ] = useAlert();
        const [ data, setData ] = useState(null)
        const [ content, setContent] = useState(null)
        const [saliendo,setSaliendo] = useState(false)

        const { user, global } = useContext(GeneralContext)

        const user_id = user.user.user.id_alumno

        const salirGrupo = () => {
            setSaliendo(true)
            post(
                global.domain+"v3-api/v3/salir_grupo_conversacion.json",
                { id, user_id },
                setAlert
            )
            window.location.href = '/escritorio'
        }

        const getData = () => {
            post(
                global.domain+"v3-api/v3/grupo_conversacion.json",
                { id, user_id },
                setData
            )            
        }

        useEffect( () => {
            getData()
            sendTrack(user_id, window.location.pathname, window.location.hostname, global);
        }, [])
        return <div> { !data || saliendo ?
                <Loader />
            : <div>
                    {(data.error === undefined || data.error === 0) && data.grupo !== undefined ?
                        <div>
                            {content !== null  && content.checktoredirectgroup === true ?
                                <Startgroup salirGrupo={salirGrupo}
                                            global={global}
                                            id={id}
                                            content={content} />
                                :
                            <Chatstructure id={id} userid={user_id}
                                       global={global}
                                       content={content}
                                       user={user}
                                       data={data}
                                       salirGrupo={salirGrupo} setContent={setContent}
                            />}
                        </div>
                        : <div>
                            <Alert bg={'primary'}>{data.errormsg}</Alert>
                            <Cardgroup global={global} data={data}/>
                            </div>
                    }
            </div>
        }
        </div>
    }


