import { Cardstructure } from "../Generals"
import { post, sendTrack } from "../Generals"
import { Alert, Col, Row } from "react-bootstrap";
import React, { useContext, useState, useEffect } from "react";
import GeneralContext from "../utils/generalContext";

export const CursosActivos = () => {

    let [ cursos, setCursos ]=useState(null)
    const { user, global } = useContext(GeneralContext)

    useEffect(()=>{
        const userid = user.user.user.id_alumno
        post(
            global.domain+"v3-api/v3/cursos_activos.json",
            { userid },
            setCursos
        )
        sendTrack(userid, window.location.pathname, window.location.hostname, global);
    },[])

    return <div className={'text-center py-5'}>
        <h3 className={'mb-4 font-weight-medium'}>Cursos activos</h3>
        {cursos !== null && cursos.length > 0 ?
            <Row className={'justify-content-center'}>
                {cursos.map(item=>{
                    let expirationdate = Date.parse(item.expirate_date)
                    let today = Date.parse(new Date())
                    return <Col lg={4} md={3} sm={12} key={'cursoactivo'+item.id}>
                        <Cardstructure width={33} img={global.domain+item.cursos_curso.img} title={item.cursos_curso.nombre}>
                            Grupos asistidos : {item.asistidogruposdeconversaciones}
                            {expirationdate < today && item.completed === 0 ? <a href={"/extender/"+item.id} class="mt-3 btn btn-outline-warning text-warning btn-block">Ampliar contrato</a>
                                : <a href={`/tracking/${item.cursos_curso.id}`} className="mt-3 w-100 btn btn-block btn-outline-success">Continuar</a> }
                        </Cardstructure>
                    </Col>})
                }
            </Row>
            :
            <Alert variant={'info'}>No tiene cursos activos</Alert>
        }
    </div>

}
