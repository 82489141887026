import React, { useEffect, useContext } from 'react';
import { Alert, Pagination } from "react-bootstrap";
import { ChevronBackOutline, ChevronForwardOutline } from "react-ionicons";
import { Cardstructure, sendTrack } from "../Generals";
import Loader from './../Components/Loader'
import GeneralContext from '../utils/generalContext';

const GlobalIndex = (props) => {
    let {title, data, content, changePage, disabledval, disabledvalnext, page, pages} = props
    const { user, global } = useContext(GeneralContext)
    const userid = user.user.user.id_alumno;

    return <Cardstructure title={title} className={'card-contact-one'}>
        {data === null ? <Loader/> : <>{data.length > 0 ? <><ul className={'list-group list-group-flush'}>
            {data.map(item=>{
                return content(item)
            })}
        </ul>
            <div className={'d-flex justify-content-between'}>
                <Pagination className={'my-3'}>
                    <Pagination.Item
                        onClick={()=>changePage(page-1)}
                        disabled={disabledval}>
                        <ChevronBackOutline
                            color={"#ccc"}
                            height="20px"
                            width="20px"
                        />
                    </Pagination.Item>

                    <Pagination.Item
                        onClick={()=>changePage(page+1)}
                        disabled={disabledvalnext}>
                        <ChevronForwardOutline
                            height="20px"
                            color={"#ccc"}
                            width="20px"
                        />
                    </Pagination.Item>
                </Pagination>
                <span className={'my-3'}>{page}/{pages}</span>
            </div>
        </> : <Alert>No hay resultados.</Alert>}</> }
    </Cardstructure>
}
export default GlobalIndex
