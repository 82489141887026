import React, { useState, useEffect, useContext } from "react"
import { post, sendTrack } from "../Generals"
import Loader from "../Components/Loader";
import GlobalIndex from "../Views/Globalindex"
import GeneralContext from "../utils/generalContext";

const Instructivos = () => {
    const [ instructivos, setInstructivos ]=useState(null)
    const [ page, setPage]=useState(1)
    const [ keyword ]=useState(null)
    const [ disabledval, setDisabledval ]=useState(true)
    const [ disabledvalnext, setDisabledvalnext ]=useState(true)
    const { user, global } = useContext(GeneralContext)
    const userid = user.user.user.id_alumno

    const changePage = (val) => {
        setInstructivos(null)
        setPage(val)
        if(val == 1){
            setDisabledval(true)
        }else{
            setDisabledval(false)
        }
        if(val < instructivos.pages){
            setDisabledvalnext(false)
        }else{
            setDisabledvalnext(true)
        }
    }

    useEffect(() => {
        post(
            global.domain+"v3-api/v3/instructivos_data.json",
            { keyword, page, userid },
            setInstructivos
        )
        sendTrack(userid, window.location.pathname, window.location.hostname, global)
    }, [])

    useEffect(() => {
        instructivos?.pages > 1 && setDisabledvalnext(false)
    }, [instructivos])

    const content = (item) => {
        return <li className="list-group-item">
            <div className="list-body">
                <a className="btn btn-info float-right" target="_blank"
                   href={global.domain+item.document}>Ver</a>
                <h6>{item.titulo}</h6>
                <p>{item.descriptiontranslated}</p>
            </div>
        </li>
    }

    return <>{instructivos== null ? <Loader/> :
        <GlobalIndex 
            title={'Instructivos'} 
            data={instructivos.instructivos} 
            content={content} 
            changePage={changePage} 
            disabledval={disabledval} 
            disabledvalnext={disabledvalnext} 
            page={page} 
            pages={instructivos.pages}></GlobalIndex>}</>
}

export default Instructivos
