import { Card } from 'react-bootstrap';
import axios from "axios";
import React, { useState } from "react";

export const Title = props => <h3 className={'mb-3 light'}>{props.value}</h3>

export const useParameters = () =>{
    let currentpar=localStorage.getItem('parameters')

    if(currentpar !== undefined && currentpar !== null && currentpar !== "[object Object]"){
        currentpar=JSON.parse(currentpar)
    }

    const [paramstoload, setParamstoload] = useState(currentpar);

    const setParams = params => {
        localStorage.setItem('parameters' , JSON.stringify(params));
        setParamstoload(params);
    }

    if(!paramstoload || paramstoload === "[object Object]") {
        const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);

        // const currentdomain=window.location.hostname;

        // if(currentdomain === 'localhost'){
        //     setParams({
        //         typeaccount:1,
        //         userid:9180746
        //     })
        // }else{
        //     setParams( JSON.parse( atob( urlParams.get('v3id') ) ) )
        // }
    }
    return [ paramstoload, setParams ];
}

export const useLoading = () => {
    const [loading, setLoadingInternal] = useState(true)
    const setLoading = val => setLoadingInternal(val)
    return [ loading, setLoading ] ;
}

export const useAlert = () => {
    let firstValue = localStorage.getItem('alert');
    if(!firstValue || firstValue !== "[object Object]"){
        firstValue=JSON.parse(firstValue)
    }

    const [alert, setAlertInternal] = useState(firstValue)

    const setAlert = alertmsg => {
        let alr= {
            error: 1,
            type: "info",
            msg: alertmsg
        }
        localStorage.setItem('alert',JSON.stringify(alr));
        setAlertInternal(alertmsg)
    }

    return [ alert, setAlert];
}

export const fetching = async (url, params=null,fn,storage=null) => {
    if(params !==  null){
        params=btoa(JSON.stringify(params))
    }
    let urlpass=url+'/'+params
    let result = await fetch(urlpass)
        .then(result=>result.text())
        .then(json=>{
            json=JSON.parse(json)
            if(storage !== null){
                storeLocal(storage,json.result)
            }
            if(fn !== null && fn !== undefined){
                fn(json.result)
                return json.result
            }

        });
    return result
}

export const post = async ( url, body, result ) => {
    let response = await axios.post(
        url,
        body
    )
    .catch(
        function (error) {
            console.error(error.message)
        }
    );
    result(response.data);
}

export const sendTrack = async ( user_id, currentUri, enviroment, global) => {
    post(
        global.domain+"v3-api/v3/recordTracking.json",
        { currentUri, user_id, enviroment },
        ()=>null
    )     
}


export const get = async ( url, body, result ) => {
    let response = await axios.get( url,{ params: body})
                              .catch((error) => {
                                        console.error(error.message)
                                    });
    result(response.data);
}

export const Numberformat = props => <span>{new Intl.NumberFormat('en-US').format(props.value)}</span>

export const Moneyformat = props => <span {...props}><small>{props.currency.simbolo}</small>$<Numberformat value={props.value*props.currency.exchange}/></span>

export const Cardstructure = (props) => <Card {...props}>
    {props.img !== undefined && props.img !== null ? <div className={'bgimgh'} style={{height:150, backgroundImage:'url('+props.img+')'}}></div> : null}
    <Card.Body>
        {props.title !== undefined && props.title !== null ? <Card.Title>{props.title}</Card.Title>:null}
        {props.text !== undefined && props.text !== null ? <Card.Text>{props.text}</Card.Text>:null}
        {props.children}
    </Card.Body>
</Card>

export const storeLocal = (key,value) => localStorage.setItem(key,JSON.stringify(value))
