import React, { useState, useContext } from "react"
import {
    Star,
    StarOutline
} from "react-ionicons";

import { post } from './../Generals.js'
import GeneralContext from "../utils/generalContext";

const RenderFeedbackRate = ({valoracion}) => {
    const stars_array = Array.from(Array(5)).map((_, i) => i++)
    return <div>
        {
            stars_array.map( (i, index) => <small key={index}>{ valoracion >= i+1 
                ? 
                    <span className="filledStar"><Star height="15px" width="15px" color={'gold'} /></span>
                : 
                    <span className="emptyStar"><StarOutline height="15px" width="15px" color={'gold'}/></span>
                }</small>)
        }
    </div>
}

const FeedBackForm = ({type, eventable_id, profesor_id, curso_id, callback = () => null}) => {
    const { user, modal, global } = useContext(GeneralContext)
    const [ valoracion, setValoracion ] = useState(0);
    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);
    const [ comentario, setComentario ] = useState(null);
    const [ , setResponse ] = useState(null);
    const stars_array = Array.from(Array(5)).map((_, i) => i++)

    const submitFeedback = () => {
        if(valoracion === 0) {
            setError('Evalue del 1 al 5');
            return;
        }
        const body = { 
            alumno_id: user.user.user.id_alumno, 
            eventable_id,
            type,
            profesor_id,
            valoracion,
            curso_id,
            comentario
        }
        
        setError(null)
        post(
            global.domain+"v3-api/v3/newFeedback.json",
            body,
            setResponse
        );
        
        setSuccess('Muchas gracias!. Hemos recibido su feedback.')
        callback(modal);
        

    }
    return <div>
        {error && <div data-testid={'error'} className={'alert alert-danger'}>{error}</div>}
        {success && <div className={'alert alert-success'}>{success}</div>}
        <label>Valora la experiencia que tuviste con este insructor.</label>
        <div>
            {
                stars_array.map((i, index) => {
                    return <button key={index} data-testid={`rate${i}`} className="btn p-0" onClick={()=>setValoracion(i+1)}>{ valoracion >= i+1 ? <span className="fillStar"><Star color={'gold'} /></span> : <span className="emptyStar"><StarOutline color={'gold'}/></span> }</button>
                })
            }
            <span className="small">{valoracion}/5</span>
        </div>
        <label>Comentario</label>
        <textarea onKeyUp={(e)=>setComentario(e.target.value)} className={"form-control"}></textarea>
        {!success && <button data-testid={'btn-submit'} onClick={submitFeedback} className="btn btn-success mt-3">Enviar feedback</button>}
    </div>
}
export default FeedBackForm;
export { RenderFeedbackRate };