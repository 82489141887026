import React, { useState, useContext } from "react"
import dateformat from 'dateformat'
import GlobalIndex from "../Views/Globalindex"
import GeneralContext from "../utils/generalContext";
import Loader from "../Components/Loader"
import { sendTrack } from "../Generals";

export const PaquetesConsulta = (props) => {
    const {user, global} = useContext(GeneralContext)
    const [paquetes,setPaquetes]=useState(null)
    const [page,setPage]=useState(1)
    const [pages,setPages]=useState(1)
    const [disabledval,setDisabledval]=useState(true)
    const [disabledvalnext,setDisabledvalnext]=useState(true)
    const userid = user.user.user.id_alumno
    const loadPaquetes = () => {
        let params = {
            userid,
            page:page
        }
        let url=global.domain+"api/v1/paqueteData/"+global.apicode+'/'
        params=btoa(JSON.stringify(params))
        url=url+'/'+params
        fetch(url).then(result=>result.json()).then(json=>{
            setPages(json.result.pages)
            if(json.result.pages > 1){
                setDisabledvalnext(false)
            }
            setPaquetes(json.result)
            return
        })
    }


    const changePage = (val) => {
        setPaquetes(null)
        setPage(val)
        if(val == 1){
            setDisabledval(true)
        }else{
            setDisabledval(false)
        }
        if(val < pages){
            setDisabledvalnext(false)
        }else{
            setDisabledvalnext(true)
        }
    }

    if(paquetes === null){
        loadPaquetes()
        sendTrack(userid, window.location.pathname, window.location.hostname, global)
    }

    const content = (item) => {
        return <li className={'list-group-item m-0 p-0'}>
            <div className="list-bodyss border-bottom w-100 p-4">
                <h3 className={'m-0'}>Paquete #{item.id} <span className="badge badge-success">Activo</span></h3>
                <p className={'m-0'}>Cantidad de sesiones en este paquete: {item.qty}</p>
                <p className={'m-0'}>Utilizados: {item.countingresult}</p>
                <p className={'m-0'}>Disponibles: {item.freesesions}</p>
                <p className={'m-0'}><span className="badge badge-primary">Disponible desde: {dateformat(item.start, 'dd mmm yyyy')}</span></p>
                <span className="badge badge-info">Expira el: {dateformat(item.expiration, 'dd mmm yyyy')}</span>
            </div>
        </li>
    }

    return <>{paquetes== null ? <Loader/> : <GlobalIndex title={'Paquetes de consultas'} data={paquetes.paquetes} content={content} changePage={changePage} disabledval={disabledval} disabledvalnext={disabledvalnext} page={page} pages={pages}></GlobalIndex>}</>

}

