import React, { useContext, useEffect, useState} from "react"
import Loader from '../Components/Loader'
import { useParams  } from "react-router-dom";
import {
    Moneyformat,
    post, 
    sendTrack
} from "../Generals"
import GeneralContext, { VerifyContractContext } from "../utils/generalContext";

export const Extender = () => {
    const { id } = useParams();
    const { global, main, user } = useContext(GeneralContext)
    const [ content, setContent ] = useState();
    const [ packid, setPackId ] = useState();
    const [ sesiones, setCantidadSesiones ] = useState();
    const [ horas, setHorasEnInstituto ] = useState();
    const [ preciounitario, setPrecio ] = useState();
    const [ formadepago, setFormaDePago ] = useState();
    const [ razon_social, setRazonSocial ] = useState('');
    const [ rfc, setRFC ] = useState('');
    const [ facturaelectronica, setFacturaElectronica ] = useState(1);
    const [ order, setOrder ] = useState();
    const [ error, setError ] = useState();
    const [ paymentProcess, setPaymentProcess ] = useState();

    const submitOrder = () => {
        setError(null)
        if(facturaelectronica == 1 && (razon_social == '' || rfc == '')){
            setError('Los campos de Razón Social y RFC son requeridos.')
            return
        }
        post(
            global.domain+"v3-api/v3/processOrder.json",
            { 
                userid: user.user.user.id_alumno,
                productid: 4,
                packid,
                horas,
                sesiones,
                qty: 1,
                facturaelectronica,
                preciounitario,
                symbol: main.currency.simbolo,
                total: preciounitario,
                formadepago
            },
            setOrder
        )
    }

    useEffect(() => {
        if(order?.id){
            post(
                global.domain+"v3-api/v3/processpayment.json",
                { orderid: order.id },
                setPaymentProcess
            )
        }
    },[order])

    useEffect(() => {
        if(paymentProcess?.link){
            window.location.href = global.domain+paymentProcess.link
        }
    },[paymentProcess])

    useEffect(() => {
        post(
            global.domain+"v3-api/v3/expired.json",
            { curso_alumno_id: id },
            setContent
        )
        sendTrack(user.user.user.id_alumno, window.location.pathname, window.location.hostname, global);
    },[])

    useEffect(() => {
        if(content?.to_redirect){
            window.location.href = content?.to_redirect
        }
        setPackId(content?.packages[0].id)
        setCantidadSesiones(content?.packages[0].cantidadsesiones)
        setHorasEnInstituto(content?.packages[0].horaseninstituto)
        setPrecio(content?.packages[0].precio)
        setFormaDePago(content?.payments[0].id)
    },[content])

    return content ? 
    <div className="row justify-content-center">
        <div className="col-lg-6 col-md-7 col-8">
            <div className="card my-5 card-body text-left">
                <h4 className="text-left">
                    El tiempo del contrato del curso <span className="text-success">{content.curso_alumno_detalle.cursos_curso.nombre}</span> ha expirado. <small>Amplie por un mes más tu servicio.</small>
                </h4>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="text-left my-3">
                    {
                        content.packages.map( (pack, index) => <div className="custom-control custom-radio mb-3">
                            <input 
                                type="radio"
                                checked={packid == pack.id }
                                onChange={(e)=>{
                                    setPackId(e.target.value)
                                    setCantidadSesiones(e.target.dataset.cantidadsesiones)
                                    setHorasEnInstituto(e.target.dataset.horaseninstituto)
                                    setPrecio(e.target.dataset.precio)
                                }}
                                className="custom-control-input packagebutton" 
                                value={pack.id} 
                                id={`package${pack.id}`}
                                data-packid={pack.id}
                                data-cantidadsesiones={pack.cantidadsesiones}
                                data-horaseninstituto={pack.horaseninstituto}
                                data-precio={pack.precio}
                                name="radio-stacked" 
                                required 
                            />
                            <label className="custom-control-label" htmlFor={`package${pack.id}`}>Ampliación por un mes, <Moneyformat
                                currency={main.currency}
                                value={pack.precio}
                            />. Incluye: {pack.cantidadsesiones > 0 && `${pack.cantidadsesiones} sesiones en línea`} {pack.horaseninstituto > 0 && `${pack.horaseninstituto} horas en el instituto`}.</label>
                        </div>
                        )
                    }
                    <label>Requiere factura electrónica</label>
                    <select 
                        defaultValue={facturaelectronica}
                        onChange={ (e) => setFacturaElectronica(e.target.value)}
                        className="form-control form-control-lg facturaelectronica" 
                        name="facturaelectronica">
                        <option value="0">No</option>
                        <option value="1">Si</option>
                    </select>

                    <div className="row mt-3 electricaspacefields" style={{
                        display: facturaelectronica == 1 ? "block" : "none"
                    }}>
                        <div className="col">
                            <label>RFC <i className="fa fas fav fa-asterisk text-danger"></i></label>
                            <input 
                                type="text" 
                                className="form-control electricalfield" 
                                onChange={(e)=>setRFC(e.target.value)} 
                                name="rfc"
                            />
                        </div>
                        <div className="col">
                            <label>Razón Social <i className="fa fas fav fa-asterisk text-danger"></i></label>
                            <input type="text" 
                                onChange={(e)=>setRazonSocial(e.target.value)} 
                                className="form-control electricalfield" name="razon_social"/>
                        </div>
                    </div>


                    <label>Formas de pago</label>
                    <select
                        onChange={ (e) => setFormaDePago(e.target.value)} 
                        className="form-control form-control-lg" 
                        name="formadepago">
                        {content.payments.map(payment => <option value={payment.id}>{payment.name}</option>)}
                    </select>

                    <button type="submit" 
                    onClick={() => submitOrder()}
                    className="btn btn-block btn-lg mt-3 btn-info">Procesar orden</button>
                </div>
                { content.payments.length === 0 && <div className="alert alert-warning">Esta franquicia no tiene formas de pago en línea configuradas. Pero, puedes comunicarte con nosotros <a className="alert-link LiveHelpButton">en el chat</a>.</div>}
                <p>¿Tiene preguntas? <a className="LiveHelpButton pointer text-info">Puede chatear con nosotros</a>.</p>
            </div>
        </div> 
    </div>
    : 
    <Loader />
}

