
import GeneralContext from "../../utils/generalContext";
import React, { useContext } from 'react'
import Avatar from "./Avatar";

const ImageProfile = () => {
    const { user, global } = useContext(GeneralContext);
    const imgprofile = user?.user?.user?.img && global.domain + user?.user?.user?.img
    return <Avatar src={imgprofile } alt={ user?.user?.user?.Nombre || null } />
}

export default ImageProfile;