import Loader from "../Components/Loader";
import DateFormatted from "../Components/DateFormatted";
import GlobalIndex from "../Views/Globalindex"
import React, { useState, useContext } from "react"
import GeneralContext from "../utils/generalContext";
import { sendTrack } from "../Generals";

export const Notificaciones = ( {openModal} ) => {
    const [notificaciones,setNotificaciones]=useState(null)
    const [page,setPage]=useState(1)
    const [pages,setPages]=useState(1)
    const [disabledval,setDisabledval]=useState(true)
    const [idSelected,setIdSelected]=useState(null)
    const [disabledvalnext,setDisabledvalnext]=useState(true)

    const {user, global} = useContext(GeneralContext)
    const userid = user.user.user.id_alumno;
    const loadNotificaciones = () => {
        let params = {
            userid,
            page:page
        }
        let url=global.domain+"api/v1/notificacionesData/"+global.apicode+'/'
        params=btoa(JSON.stringify(params))
        url=url+'/'+params
        fetch(url).then(result=>result.json()).then(json=>{
            setPages(json.result.pages)
            if(json.result.pages > 1){
                setDisabledvalnext(false)
            }
            setNotificaciones(json.result)
            return
        })
    }

    const markasRead = () => {
        let params = {
            id:idSelected
        }
        let url=global.domain+"api/v1/markedAsRead/"+global.apicode+'/'
        params=btoa(JSON.stringify(params))
        url=url+'/'+params
        fetch(url).then(result=>result.json()).then(json=>{
            setNotificaciones(null)
            return
        })
    }

    const changePage = (val) => {
        setNotificaciones(null)
        setPage(val)
        if(val == 1){
            setDisabledval(true)
        }else{
            setDisabledval(false)
        }
        if(val < pages){
            setDisabledvalnext(false)
        }else{
            setDisabledvalnext(true)
        }
    }

    if(notificaciones === null){
        loadNotificaciones()
        sendTrack(userid, window.location.pathname, window.location.hostname, global)
    }

    const openAndMarkAsRead = (subject,body,id,readed) => {
        setIdSelected(id)
        openModal(subject,body)
        if(!readed){
            markasRead()
        }
    }

    const content = (item,index) => {
        let classNmae="bg-light"
        if(item.readed){
            classNmae='bgwhite';
        }
        return <li key={'notificaciones'+item.id} className={'list-group-item m-0 p-0'}>
                <div className={"mail-item-body p-3 w-100 "+classNmae}>
                    <button className="link text-left d-block w-100 loadnotificacion"
                            onClick={()=>openAndMarkAsRead(item.subject,item.body,item.id,item.readed)}>
                        <div className={'d-flex justify-content-between align-items-center'}>
                            <h6 className={'text-left'} style={{width:250}}>{item.subject}</h6>
                            <div className={'px-3 text-left justify-content-start'} dangerouslySetInnerHTML={{__html:item.short}}></div>
                            <div>
                                <span className={'badge badge-info'}><DateFormatted value={item.date} /></span>
                            </div>
                        </div>
                    </button>
                </div>
        </li>
    }

    return <>{notificaciones== null ? <Loader/> : <GlobalIndex title={'Notificaciones'} data={notificaciones.notificaciones} content={content} changePage={changePage} disabledval={disabledval} disabledvalnext={disabledvalnext} page={page} pages={pages}></GlobalIndex>}</>


}
