import { Cardstructure, post, sendTrack } from "../Generals"
import { Alert, Col, Row } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import { MedalOutline } from "react-ionicons"
import GeneralContext from "../utils/generalContext";

export const CursosCompletados = () => {
    let [ cursos, setCursos ]=useState(null)
    const { user, global } = useContext(GeneralContext)

    useEffect(()=>{
        const userid = user.user.user.id_alumno;
        post(
            global.domain+"v3-api/v3/cursos_completados.json",
            { userid },
            setCursos
        )
        sendTrack(userid, window.location.pathname, window.location.hostname, global);
    },[])

    return <div className={'text-center py-5'}>
        <h3 className={'mb-4 font-weight-medium'}>Cursos completados</h3>
        {cursos !== null && cursos.length > 0 ?
            <Row className={'justify-content-center'}>
                {cursos.map(item=>{
                    return <Col lg={4} md={3} sm={12}>
                        <Cardstructure img={global.domain+item.cursos_curso.img} title={item.cursos_curso.nombre}>
                            <MedalOutline
                                color={'#00000'}
                                height="30px"
                                width="30px"
                            />
                        </Cardstructure>
                    </Col>})
                }
            </Row>
            :
            <Alert variant={'info'}>No tiene cursos completados</Alert>
        }
    </div>

}
