import React, { useContext, useEffect, useState } from 'react'
import { useParams  } from "react-router-dom";

import Loader from '../Components/Loader';
import { Title, post, sendTrack } from "../Generals"
import {Accordion} from "react-bootstrap"
import GeneralContext from "../utils/generalContext";

const SubTitle = (props) =><h5 className={'mb-3 light'}>{props.value}</h5>

function Legend(props){
    return (
        <p className={'text-uppercase mb-0 text-secondary light'}>
            <small>
                {props.value}
            </small>
        </p>
    )
}

function Button(props){
    return (
        <a href={props.href} className={"btn "+props.class}>{props.value}</a>
    )
}

function CurrentModule(props){
    let item=props.item;
    if(item?.id !== undefined){
        return (
            <div className={'card card-body card-todo mb-3'}>
                <Legend key={"legend"+item.id} value='¿Dónde estas?' />
                <SubTitle key={"subtitle"+item.id} value={item.cursos_item.titulo+" - "+item.cursos_item.cursos_modulo.titulo} />
                <div className={'card-progress'}>
                    <label className={'content-label mg-b-0'}>Progreso</label>
                    <div className={'progress'}>
                        <div className={'progress-bar wd-'+item.porciento+'p'} role="progressbar" aria-valuenow={item.porciento} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <label className={'content-label mg-b-0'}>{item.porciento}%</label>
                </div>
                <a
                 href={`/tracking/${item.cursos_item.cursos_modulo.cursoid}`}
                className={'btn btn-info text-white'}>Continuar</a>
            </div>
        );
    }else{
        return (
            <div>
                <Button key={item?.id} href={''} class={'btn-info text-white mb-3'} value={'Comenzar'}/>
            </div>
        )
    }
}
function ItemStats(props){
    let data
    let icon
    let hechas
    if (props.type == 1){
        data=props.data.counttexto
        hechas=props.data.counttextohechos
        icon='<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-align-left fa-stack-1x fa-inverse"></i></span>'
    }

    if (props.type == 2){
        data=props.data.countvideos
        hechas=props.data.countvideoshechos
        icon='<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-video fa-stack-1x fa-inverse"></i></span>'
    }

    if (props.type == 3){
        data=props.data.countaudio
        hechas=props.data.countaudiohechos
        icon='<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-volume-up fa-stack-1x fa-inverse"></i></span>'
    }

    if (props.type == 4){
        data=props.data.countpreguntas
        hechas=props.data.countpreguntashechas
        icon='<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-question fa-stack-1x fa-inverse"></i></span>'
    }

    if (props.type == 5){
        data=props.data.countassigment
        hechas=props.data.countassigmenthechos
        icon='<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-check fa-stack-1x fa-inverse"></i></span>'
    }

    if (props.type == 6){
        data=props.data.countpdf
        hechas=props.data.countpdfhechos
        icon='<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-file-pdf fa-stack-1x fa-inverse"></i></span>'
    }

    if (props.type == 7){
        data=props.data.counthtml
        hechas=props.data.counthtmlhechos
        icon='<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x"></i><i class="fab fa-html5 fa-stack-1x fa-inverse"></i></span>'
    }

    if (props.type == 8){
        data=props.data.countorales
        hechas=props.data.countoraleshechos
        icon='<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x"></i><i class="fas fa-chalkboard-teacher fa-stack-1x fa-inverse"></i></span>'
    }

    let label = props.label
    if(data > 0){
        return(
            <div className={'col-6'}>
                <p><span style={{fontSize:'8px'}} dangerouslySetInnerHTML={{__html: icon}}></span>{label}: <span className={'text-success'}>{hechas}</span>/{data}</p>
            </div>
        )
    } else{
        return(
            <div></div>
        )
    }
}

function Stats(props) {
    let data=props.data
    return (
        <div className={'row'}>
            <ItemStats key={1} label={'Textos'} data={data} type={1}/>
            <ItemStats key={2} label={'Videos'} data={data} type={2}/>
            <ItemStats key={3} label={'Audios'} data={data} type={3}/>
            <ItemStats key={4} label={'Preguntas'} data={data} type={4}/>
            <ItemStats key={5} label={'Asignaciones'} data={data} type={5}/>
            <ItemStats key={6} label={'PDF'} data={data} type={6}/>
            <ItemStats key={7} label={'HTML'} data={data} type={7}/>
            <ItemStats key={8} label={'Prácticas Orales'} data={data} type={8}/>
        </div>
    )
}

const Modules = ( {modules, cursoid, global} ) => {
    const changetoActual = (item) =>{
        let params = {
            moduleid:item.id,
            cursoid:cursoid
        }
        params=btoa(JSON.stringify(params))
        let url=global.domain+'api/api/changetoActual/'+params+'/'+global.apicode
        fetch(url).then(
            function(response) {
                window.location.href = "/cursos/continuarnuevo2/"+cursoid;
            }
        );
    }

    const ItemModule = (item) => {
        let classtoShow='';
        let htmlactual = '';
        let goIn;
        if(item.statusactual == 1){
            classtoShow='show';
            htmlactual='<span class="badge badge-primary float-right">Actual</span>';
            goIn=<a href={'/cursos/continuarnuevo2/'+cursoid} className="btn btn-info btn-block">Continuar</a>
        }

        if(item.statusdone == 1){
            htmlactual='<i class="fas fa-check-circle text-success float-left mt-1 mr-2"></i>';
            goIn=<button className="btn btn-block btn-secondary" onClick={()=>{changetoActual(item)}}>Consultar asignaciones pasadas</button>
        }

        return <Accordion.Item key={'modulesto'+item.id} eventKey={item.id}>
            <Accordion.Header>{item.titulo} <span className={'ml-3'} dangerouslySetInnerHTML={{__html: htmlactual}}></span></Accordion.Header>
            <Accordion.Body>
                <span dangerouslySetInnerHTML={{__html: item.objetivos}}></span>
                <Stats key={item.id} data={item}/>
            </Accordion.Body>
        </Accordion.Item>
    }

    return <Accordion defaultActiveKey="0">
        {
            modules?.map(item=>{
                return ItemModule(item)
            })
        }
    </Accordion>
}

function CursoGeneral(props){
    let curso = props.detail.curso
    let modulos = props.detail.modulos
    let moduloactual = props.detail?.moduloactual
    let domain=props.global.domain

    return(
        <div className={'card'}>
            <div className={'card-body'}>
                <Title key={'Title'+curso?.id} value={curso?.nombre} />
                <p>{curso?.valordescripcion}</p>
                <CurrentModule key={'modulo'+curso?.id} item={moduloactual}/>
                <Modules domain={domain} global={props.global} cursoid={curso?.id} key={'modules'+curso?.id} state={props.state} modules={modulos}/>
            </div>
        </div>
    )
}

export const General = () => {
    const [ pagenumber, setPageNumber ] = useState(1);
    const [ data, setData ] = useState(undefined);
    const [ loadingPagination, setLoadingPagination ] = useState(false);
    let arraypages=[];
    const { id } = useParams();
    const { user, global } = useContext(GeneralContext)
    const userid = user.user.user.id_alumno;
    const changePage = (val) => {
        setLoadingPagination(true);
        setPageNumber(val);
        loadData();
    }
    console.log(global.domain+"v3-api/v3/overview_course.json")
    const loadData = () => {
        post(
            global.domain+"v3-api/v3/overview_course.json",
            { 
                cursoid: id,
                userid,
                page:pagenumber
            },
            setData
        )
    }

    useEffect(() => {
        loadData();
        setLoadingPagination(false);
        sendTrack(userid, window.location.pathname, window.location.hostname, global);
    }, [])

    useEffect(() => {
        if(data){
            let totalpages=data.totalpagesmodulos;
            for(var i=1; i<=totalpages; i++){
                arraypages.push(i);
            }
        }
    }, [data])
    return data ?
            <div className={'container-fluid mt-3'}>
                <div className={'row justify-content-left'}>
                    <div className={'col-12 col-md-8'}>
                        <CursoGeneral key={"general"+1} detail={data} global={global}/>
                        {loadingPagination ? <Loader/>
                            :
                            <ul className={'pagination mt-3'}>
                                {
                                    arraypages?.map((item)=>{
                                        let classValue='';
                                        if (item == pagenumber){
                                            classValue='active';
                                        }
                                        return(
                                            <li key={item} className={'page-item '+classValue}>
                                                <a className={'page-link'} onClick={()=>changePage(item)}>
                                                    {item}
                                                </a>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        }
                    </div>

                    <div className={'col-12 col-md-4'}>
                        <img src={global.domain+'/'+data?.curso?.img}/>
                        <a href="/escritorio"
                                className={'btn btn-secondary w-100 btn-block mt-3 mr-3'} >Volver al Escritorio</a>
                        <a href={"/cursos-activos"}
                                className={'btn btn-light w-100 btn-block mt-3 mr-3'}>Mis cursos</a>
                    </div>
                </div>
            </div>
            : <div><Loader /></div>

}

