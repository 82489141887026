import {
    fetching,
    sendTrack
} from "../Generals";
import Loader from "../Components/Loader";
import DateFormatted from "../Components/DateFormatted";
import React, { useState, useEffect, Component, useContext } from "react"
import { useParams } from "react-router-dom";

import noimg from './../no-img.jpg'
import GeneralContext from "../utils/generalContext";

const Itemchat = (props) => {
        let content=props.content
        let global=props.global
        let field='nombre'

        if(props.type === 1){
            field='Nombre'
        }

        let img = noimg
        if(content.img !== null ){
            img=global.domain+content.img
        }
        return <div key={'contentitem'+props.type+content.id} className={'chat-item'}>
            <div className="avatar avatar-online "><span className="avatar-initial bgimgh rounded-circle" style={{backgroundImage:'url('+img+')'}}></span></div>
            <div className="chat-item-body"><div><span>{content[field]}</span></div></div>
        </div>
    }

const Cardgroup = (props) =>{
    let {global,data} = props
    return <div className="card card-hover card-profile-one">
        <div className="row no-gutters">
            <div className="col-md">
                <div className="relative bgimgh"
                     style={{backgroundImage:'url('+global.domain+data.grupo.cursos_grupos_conversaciones_tema.img+')', overflow: 'hidden', height: '100%'}}>
                </div>
            </div>
            <div className="col-md-7">
                <div className="card-body px-0">
                    <div className="media">
                        <div className="">
                            <h5 className="card-title">{data.grupo.nombre} - <small
                                className="text-muted">{data.grupo.cursos_grupos_conversaciones_tema.nombre}</small></h5>
                            {data.grupo.descripciontranslate !== null && data.grupo.descripciontranslate !=="" ? <p className="card-desc" dangerouslySetInnerHTML={{__html:data.grupo.descripciontranslate}}></p> : null }
                            <div className="media-footer">
                                <div style={{marginRight:10}}>
                                    <h6>{data.grupo.countmembersactive}/{data.grupo.limite}</h6>
                                    <label>Unidos</label>
                                </div>
                                <div><h6><DateFormatted value={data.grupo.date}/></h6>
                                    <label>Hora Programada</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
}

function Card(props){
    let headertitle=props.header
    let linkname=props.linkname
    return (
        <div className={'card card-hover card-contact-one'}>
            <div className="card-header py-2">{headertitle}</div>
            <div className={'card-body'}>{props.content}</div>
            {linkname !== "" ? <a className="btn btn-secondary mt-3" href={props.link}>{linkname}</a> : null }
        </div>
    );
}

class Info extends Component{
    constructor(props){
        super(props)
        this.state={
            domain:props.domain,
            message:"",
            loading:true,
            loadingd:true,
            loadingdt:true,
            userid:props.userid,
            profesordetail:{},
            resources:{},
            started:0
        }
    }

    loadData(){
        let props=this.props;
        let profesordetail='https://cursos.birmingham-institute.com/api/getprofesorbygroup/'+props.groupid+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'

        fetch(profesordetail)
            .then(function(response) {
                return response.json();
            })
            .then((myJsond) => {
                this.setState({
                    loading:false,
                    profesordetail: myJsond
                });
            });

        let getgroup='https://cursos.birmingham-institute.com/api/getInfod/CursosGruposConversaciones/'+props.groupid+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy/'+props.userid

        fetch(getgroup)
            .then(function(response) {
                return response.json();
            })
            .then((myJsond) => {
                let temaid=myJsond.temaid
                let resourcesdetail='https://cursos.birmingham-institute.com/api/getRecursos/'+temaid+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'

                fetch(resourcesdetail)
                    .then(function(response) {
                        return response.json();
                    })
                    .then((myJsondds) => {
                        this.setState({
                            loadingdt:false,
                            resources: myJsondds
                        });
                    });

            });



    }

    componentDidMount() {
        this.loadData()
    }

    componentWillUnmount() {
    }


    Profesordetails(){
        let profesor=this.state.profesordetail;

        return (
            <div className={'text-center'}>
                <span className={"rounded-circle d-inline-block bgimgh ml-1 tooltipshow"}
                      style={{width:"100px", height:"100px", backgroundImage:"url("+this.state.domain+"/"+profesor.img+")"}}>
                </span>
                <h6>{profesor.nombre}</h6>
            </div>
        )

    }

    Resources(){
        let resources=this.state.resources;
        let counting=0
        if(this.state.loadingdt == true){
            return <div></div>
        } else{
            return <div>
                {resources.length > 0 ?
                    <div className="card mt-3">
                        <div className="card-header py-2"><p className={'mb-0'}>Listado de materiales</p></div>
                        <ul className="list-group">
                            {
                                resources.map(function(item){
                                    let spanvalue="";
                                    counting++;
                                    if(item.previo == 1){
                                        return(
                                            <li className="list-group-item"><a target={'_blank'} href={item.value}>Recurso {counting}
                                                <span className='badge badge-success float-right'>Para ver antes del club de conversación.</span></a>
                                            </li>
                                        )
                                    }else{
                                        return(
                                            <li className="list-group-item"><a target={'_blank'} href={item.value}>Recurso {counting}</a></li>
                                        )
                                    }

                                })}
                        </ul>
                    </div>
                    : null}
            </div>
        }

    }

    render(){
        if(this.props.type == 1){
            return this.Resources()
        }else if(this.props.type == 2){
            return this.Profesordetails()
        }

    }
}

const Refresh = (props) => {
    const { type, state } = props
    const { timeback, members } = state;

    const timeToJoin = () => {
        if(timeback && state?.loading == false){
            if(timeback.timelimit == ''){
                return (
                    <span className={'mb-3'}>
                    <span className="badge-light badge">Comenzará en {timeback?.timeleft}</span>
                </span>
                )
            }else{
                return (
                    <span className={'mb-3'}>
                    <span className="badge-light badge">Únete antes de {timeback?.timelimit}</span>
                </span>
                )
            }
        }else{
            return (
                <div></div>
            )
        }
    }

    const countingMembers = () => {
        let message = members;
        let groupdetail = members?.groupdetail
        return groupdetail && (
            <div>
                <h6>{message.activemembers}/{groupdetail.limite}</h6>
                <label>Unidos</label>
            </div>
        )

    }

    if( type == 1 ) { return timeToJoin() }
    if( type == 2 ) { return countingMembers() }
    if( ![1,2].includes(type) ) { return Profesordetails() }
}

class Groupdetail extends Component{

    constructor(props) {
        super(props)
        this.state = {
            domain :'https://cursos.birmingham-institute.com/',
            loading:true,
            group:props.groupid,
            topic:props.topic,
            userid:props.userid,
            topicdetails:{},
            groupdetail:{},
            timeback: undefined,
            members: undefined
        };
    }

    async loadDataOnInterval(){
        let status='https://cursos.birmingham-institute.com/api/timeback/'+this.state.group+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'
        let timeback = await fetch(status);
        timeback = await timeback.json();

        let countmembers='https://cursos.birmingham-institute.com/api/countmembers/'+this.state.group+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'
        let members = await fetch(countmembers);
        members = members.json();
        this.setState({
            timeback,
            members
        });
    }

    async componentDidMount() {
        let value=this.state
        let coursdetail='https://cursos.birmingham-institute.com/api/getInfod/CursosGruposConversaciones/'+value.group+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy/'+value.userid
        let topicdetail='https://cursos.birmingham-institute.com/api/getInfod/CursosGruposConversacionesTemas/'+value.topic+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy/'+value.userid

        coursdetail = await fetch(coursdetail);
        coursdetail= await coursdetail.json();
        topicdetail = await fetch(topicdetail);
        topicdetail= await topicdetail.json();
        let status='https://cursos.birmingham-institute.com/api/timeback/'+this.state.group+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'
        let timeback = await fetch(status);
        timeback = await timeback.json();

        let countmembers='https://cursos.birmingham-institute.com/api/countmembers/'+this.state.group+'/pAiPZ1BNInRc23kq7MtoWsa5FxLXQ9dVzCYSerDfvK6mEwTJgUbG4j0OH8luhy'
        let members = await fetch(countmembers);
        members = await members.json();

        this.setState({
            loading:false,
            groupdetail: coursdetail,
            topicdetails: topicdetail,
            timeback,
            members
        });

        this.intervalID = setInterval(
            () => this.loadDataOnInterval(),
            2500
        );

    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }


    render() {
        let info=this.state
        if(info.loading == true || info.loadingd == true){
            return (<div>Loading</div>)
        }
        else{
            if(info.topicdetails.img == null){
                info.topicdetails.img="img/noimagen.png";
            }
            return (
                <div>
                    <div className="card card-hover card-profile-one">
                        <div className="row no-gutters">
                            <div className="col-md-3">
                                <div className="relative border-right"
                                     style={{backgroundSize:"auto 100%", backgroundImage:"url("+this.state.domain+"/"+info.topicdetails.img+")", overflow: "hidden", height: "100%"}}>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="card-body">
                                    <div className="media">
                                        <div className="">
                                            <h5 className="card-title mb-1">
                                                {info.topicdetails.nombre} <small className="text-muted">{info.groupdetail.nombre}</small>
                                            </h5>
                                            <span className={'badge badge-info mr-2'}>{info.groupdetail.edad}</span>
                                            <Refresh key={1} type={1} groupid={info.groupdetail.id} state={this.state} />
                                            <p className="card-desc mt-1">
                                                {info.topicdetails.valordescripcion}
                                            </p>
                                            <div className="media-footer">
                                                <Refresh key={1} type={2} groupdetail={info.groupdetail} groupid={info.groupdetail.id} state={this.state}/>
                                                <div>
                                                    <h6>{info.groupdetail.dateformated}</h6>
                                                    <label>Hora Programada</label>
                                                </div>
                                                {info.groupdetail.activo ?
                                                    <div>
                                                        <a href={"/chat/"+info.groupdetail.id} className="btn btn-sm btn-primary">Entrar al club</a>
                                                    </div>
                                                    : <div>
                                                        <div className={'alert alert-danger'}>Este club no está activo.</div>
                                                    </div>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

function Resources(props) {
    return(
        <Info key={1} domain={props.domain} type={1} userid={props.userid} groupid={props.groupid}/>
    )
}

export const DetalleClub = () => {
        const { user, global } = useContext(GeneralContext)
        const {id} = useParams();
        let userid = user.user.user.id_alumno
        const [ data, setData ] = useState(null)

        const getData = () => {
            let params = {
                id,
                userid
            }
            params=btoa(JSON.stringify(params))
            let url=global.domain+"api/v1/detalleGrupoConversacion/"+global.apicode+'/'+params
            fetching(url,params,setData,null)
        }

        useEffect(() => {
            getData()
            sendTrack(user.user.user.id_alumno, window.location.pathname, window.location.hostname, global);
        },[])

        return <div> {data === null ? <Loader/> : <div className={'row justify-content-center'}>
                <div className={'col-12 col-md-10'}>
                    <a className={'text-secondary d-inline-block my-4 link'}
                       href={'/escritorio'}>Volver</a>
                </div>
                <div className={'col-12 col-md-7'}>
                    <Groupdetail key={1} groupid={id} userid={userid} topic={data.grupo.temaid} />
                    <Resources key={1} groupid={id} userid={userid} domain={global.domain}/>
                </div>
                <div className={'col-12 col-md-3'}>
                    <div className={'card'}>
                        <div className={'card-header  py-2'}>Profesor organizador</div>
                        <div className={'card-body'}>
                            <Info key={1} type={2} groupid={id} userid={userid} domain={global.domain}/>
                        </div>
                    </div>
                </div>
            </div> }
        </div>
    }


