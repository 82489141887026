import GeneralContext from "../../utils/generalContext";
import React, { useContext } from 'react'

const Avatar = (props) => {
    const { src, alt = null, title = null } = props
    const { noimg } = useContext(GeneralContext);
    return <div className="avatar d-inline-block ml-1" {...props}><img data-testid='avatar' src={ src || noimg } className="rounded-circle" alt={ alt } title={ title || alt } /></div>
}

export const BigAvatar = ({ src }) => {
    const { noimg } = useContext(GeneralContext);
    const img = src || noimg
    return <div className="avatar">
            <span className="avatar-initial bgimgh rounded-circle" style={{backgroundImage:'url('+img+')'}}></span>
        </div>
}

export default Avatar;