import { useContext } from "react";
import logo from "../../logo.png";
import packageJson from '../../../package.json';
import GeneralContext from "../../utils/generalContext";

    const SidePanelHome = ( {children} ) => {
        const { main, global } = useContext(GeneralContext);
        return main && global && (
            <div className="signin-panel" style={{backgroundImage:'url('+global.domain+main.main.img+')'}}>
                <div className="signin-sidebar">
                    <div className="signin-sidebar-body">
                        <a href="/" className="sidebar-logo w-100">
                            <img src={logo}/>
                        </a>
                        {children}
                        <p className="mg-t-20 mg-b-0 tx-sm tx-color-03">Al identificarse, está de acuerdo con los <a href="/terminos-de-uso/8">Términos y condiciones de uso</a> y las <a  href="/politicas-de-privacidad/7">Políticas de Privacidad</a></p>
                        <p className="tx-sm tx-color-03">v{packageJson.version}</p>
                        <p className="mg-t-20 mg-b-0 tx-sm tx-color-03"><a href="/login-profesor">Profesores</a> | <a  href="/admins">Admins</a></p>
                    </div>
                </div>
            </div>
        );
    }

export default SidePanelHome;