import React from 'react';

const CampoDeTexto = ( props ) => {
    const { item, actualmodule, pastvaluecorrect, pastvalueteachercomment, teacherquestion, autoSave, savedid, classbginput, pastvalue } = props.props
    return <div className={'col'}>
        <div key={'questions'+item.id}>
            {actualmodule?.corrected &&
                <div className={'alert alert-light border mb-2'}>
                    { pastvaluecorrect && <span className="badge badge-success mb-2">Respuesta aprobada</span> }
                    { !pastvaluecorrect && <span className={'badge badge-danger mr-2'}>Incorrecto</span> }
                    { pastvalueteachercomment !== null && <span>{pastvalueteachercomment}</span> }
                    { teacherquestion !== null && <strong> - {teacherquestion}</strong> }
                </div>
            }
        
            <div data-type={1} className={'questionsfield'}>
                <textarea readOnly={pastvaluecorrect === true && actualmodule?.corrected === true ? true : false}
                          onBlur={pastvaluecorrect === true && actualmodule?.corrected === true ? null : autoSave} 
                          data-type={1} name={'field'+item.id} data-savedid={savedid} data-id={item.id} 
                          required={'required'} id={'fieldquestions'+item.id} 
                          className={'fieldquestions form-control mb-3 '+classbginput} defaultValue={pastvalue}></textarea>
            </div>
        </div>
    </div>
}

export default CampoDeTexto;