import React, {useState, useEffect, useContext} from "react"
import Loader from "../Components/Loader"
import {Row,Col,Card} from 'react-bootstrap'

import { Cardstructure, post, sendTrack } from "../Generals"
import dateformat from 'dateformat'
import GeneralContext from "../utils/generalContext";

export const Dashboard = () => {
    const { user, global } = useContext(GeneralContext)
    const [ dashboard, setDashboard ] = useState(false)

    const loadDashboard = () => {
        const userid = user.user.user.id_alumno
        post(
            global.domain+"v3-api/v3/dashboard.json",
            { userid },
            setDashboard
        )
        sendTrack(userid, '/escritorio', window.location.hostname, global);
    }

    useEffect(()=>{
        loadDashboard()
    },[])

    return <div> { !dashboard ? <Loader/> :
        <Row>
            <Col>
                <Cardstructure>
                    <Row>
                        <Col>
                            <h6 className="card-title">Consultas hechas</h6>
                            <div className="d-flex align-items-center justify-content-between mg-b-10">
                                <h1 className="card-value">{dashboard.sessionused}</h1>
                                <a className="btn btn-success"
                                   href={"/historial-de-consultas"}
                                   >Ver historial</a>
                            </div>
                        </Col>
                        <Col>
                            <h6 className="card-title">Consultas disponibles</h6>
                            <div className="d-flex align-items-center justify-content-between mg-b-10">
                                <h1 className="card-value">{dashboard.sesionesDisponibles}</h1>
                                <a className="btn btn-success"
                                        href={"/escoger-consulta"}
                                        >Haz una consulta</a>
                            </div>
                        </Col>
                    </Row>
                </Cardstructure>
                <Row>
                {dashboard.cursos.map(item=>{
                    let expirationdate=Date.parse(item.expirate_date)
                    let now = new Date()
                    let today = Date.parse(dateformat(now, "isoDateTime"))
                    let status =""
                    if(expirationdate < today && item.completed === 0){
                        status=<span className='badge badge-danger mb-3'>Expirado</span>
                    } else if(expirationdate > today && item.completed === 0){
                        status=<span className='badge badge-info mb-3'>Activo</span>
                    } else if(item.completed === 1){
                        status=<span className='badge badge-success mb-3'>Completado</span>
                    }
                    let percent=item.percentdone
                    return <Col key={'dashkey'+item.id} sm={12} md={6} className={'pt-4'}>
                        <Cardstructure img={global.domain+item.cursos_curso.img} key={'cardcursos'+item.id}>
                            <Card.Title>{item.cursos_curso.nombre}</Card.Title>
                            <Card.Text>{item.cursos_curso.descriptiontranslated}</Card.Text>
                            {status}
                            <div className="progress mb-3" style={{height: "10px"}}>
                                <div className="progress-bar progress-bar-striped bg-success" role="progressbar" style={{width:percent, height: '10px'}} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            {expirationdate < today && item.completed === 0 ?
                                <a href={"/cursos/redirectvencimiento/"+item.cursos_curso.id}
                                   className="btn btn-outline-warning text-warning btn-block">Ampliar contrato</a>
                                : <div> {item.stage.notstarted === true ? <div>
                                    <a className='btn btn-outline-info text-info' href={`/tracking/${item.cursos_curso.id}`}>Comenzar ahora!</a>
                                </div> : <div>
                                    <h6>{item.stage.details.cursositem.titulo} <small>{item.stage.details.cursositem.cursosmodulos.nombre}</small></h6>
                                    {item.stage.details.cursositem.descripcion !== "" && item.stage.details.cursositem.descripcion !== null ? <p>{item.stage.details.cursositem.descripcion}...</p> : null}
                                    <a className='btn btn-outline-info text-info' href={`/tracking/${item.cursos_curso.id}`}>Continuar</a>
                                </div>}</div>
                            }
                        </Cardstructure>
                    </Col>
                })}
                </Row>
            </Col>
            <Col>
                <div className="card card-hover card-customer-score ">
                    <div className="card-header bg-transparent">
                        <h6 className="card-title m-0">Clubes de Conversación <span className="badge badge-primary ">Conteo de clubs: {dashboard.clubconversacion.conteo}</span></h6>
                        <div>
                            {user.user.user.cursos_zonas_horaria.nombre} <a className="text-primary" href={'/modificar-perfil'}>Cambiar?</a>
                        </div>
                    </div>
                    <div className="card-body pd-t-10">
                        <div className="alert small alert-warning">Los clubes de conversación se abrirán 5 minutos antes del tiempo programado y se cerrarán 10 minutos después de comenzado.</div>
                        {dashboard.clubconversacion !== null ? <>
                            {dashboard.clubconversacion.actives.length > 0 ? <div>
                                <p className="content-label mt-3 mg-b-5 tx-secondary">Club activo </p>
                            </div> : null}
                            {dashboard.clubconversacion.actives.map((clubes,index)=>{
                                let imgbg
                                if(clubes.cursos_grupos_conversaciones_tema.img !== null){
                                    imgbg=clubes.cursos_grupos_conversaciones_tema.img
                                }else{
                                    imgbg=clubes.cursos_curso.img;
                                }
                                let started= dateformat(clubes.startedplus10, 'dd mmm h:MM TT')
                                let date = dateformat(clubes.date,'dd mmm yyyy')
                                let hour = clubes.hour
                                
                                return <div key={'clubesactives'+clubes.id+index}>
                                    <div className="card card-hover card-profile-one">
                                        <div className="row no-gutters">
                                            <div className="col-md-3">
                                                <div className="relative"
                                                     style={{backgroundSize:'cover', backgroundImage:'url('+global.domain+imgbg+')',overflow: 'hidden', height: '100%'}}>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <div className="card-body">
                                                    <div className="media">
                                                        <div className="">
                                                            <h5 className="card-title">
                                                                <a href={`/detalle-club/${clubes.id}`}>
                                                                    { clubes.cursos_grupos_conversaciones_tema.nombre } - <small
                                                                    className="text-muted">{clubes.nombre}</small>
                                                                </a>
                                                            </h5>
                                                            {clubes.started !== null ? <div>
                                                                <span className="badge-success badge">Ya comenzó pero estas a tiempo de unirte!</span>
                                                                <br/>
                                                                <span className="badge-light badge">Hazlo antes de las {started}</span>
                                                            </div> : null}

                                                            <p className="card-desc">{clubes.cursos_grupos_conversaciones_tema.descriptiontranslated}</p>

                                                            <div className="media-footer">
                                                                <div>
                                                                    <h6>{clubes.countmembersactive}/{clubes.limite}</h6>
                                                                    <label>Unidos</label>
                                                                </div>
                                                                <div>
                                                                    <h6>{date} {hour}</h6>
                                                                    <label>Hora Programada</label>
                                                                </div>
                                                                <div><a href={`/detalle-club/${clubes.id}`} className="btn btn-sm btn-info">Entrar
                                                                    al club</a></div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </> : null }

                        {dashboard.clubconversacion.comming.length > 0 ? <div>
                            <p className="content-label mt-3 mg-b-5 tx-secondary">Próximos clubes </p>
                        </div> : null}
                        {dashboard.clubconversacion.comming.map((clubes,index)=>{
                            let imgbg
                            if(clubes.cursos_grupos_conversaciones_tema.img !== null){
                                imgbg=clubes.cursos_grupos_conversaciones_tema.img
                            }else{
                                imgbg=clubes.cursos_curso.img;
                            }
                            let date= dateformat(clubes.date,'dd mmm yyyy')
                            let hour= clubes.hour
                            return <div className={'d-flex align-items-center justify-content-between border-bottom py-2'} key={'clubescomming'+clubes.id+index}>
                                <span className="bgimgh float-left mr-2 d-inline-block mb-3" style={{
                                    backgroundImage: 'url(' + global.domain + imgbg + ')',
                                    height: '60px',
                                    width: '75px'
                                }}></span>
                                <div style={{width:'60%'}}>
                                    <a className="link text-left float-left p-0 d-inline-block"
                                        href={`/detalle-club/${clubes.id}`}>
                                        {clubes.cursos_grupos_conversaciones_tema.nombre} - {clubes.cursos_grupos_conversaciones_categoria.nombre}
                                    </a>
                                    <br/>
                                    <small>Impartido por {clubes.cursos_profesore.nombre} {clubes.edad !== null && clubes.edad !== "" ? <span>{clubes.edad}</span> :null}</small>
                                    {clubes.cursos_grupos_conversaciones_tema.cursos_grupos_conversaciones_temas_recursos.length > 0 ? <>
                                        <br/><span className="badge badge-primary">
                                                        <a 
                                                            className="link text-white text-left float-left p-0 d-inline-block"
                                                            href={`/detalle-club/${clubes.id}`}>
                                                            <i className="fas fa-info-circle"></i> Tiene recursos para ver previo al club. Click para ver.
                                                        </a>
                                                    </span></> :null}
                                </div>
                                <div className={'px-2 text-center'}>
                                    {date}
                                </div>
                                <div className={'px-2 text-center'}>
                                    {hour}
                                </div>

                            </div>
                        })}
                </div>
            </div>
            </Col>
        </Row>
    }
    </div>
}

