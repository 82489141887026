import {Row,Col,Alert} from 'react-bootstrap';
import { Cardstructure, sendTrack } from "../Generals"
import React, { useContext, useEffect } from "react";
import GeneralContext from "../utils/generalContext";

export const ChooseConsulta = () => {
    const { user, global } = useContext(GeneralContext)

    useEffect(()=>{
        const user_id = user.user.user.id_alumno
        sendTrack(user_id, window.location.pathname, window.location.hostname, global);
    },[])

    return <div className={'text-center py-5'}>
        <h3 className={'mb-4 font-weight-medium'}>¿Cuál curso quiere consultar?</h3>
        { user?.user?.activeCursos?.length > 0 ?
            <Row>
                {user?.user?.activeCursos?.map(item=><Col key={'chooeconsl'+item.id}>
                    <Cardstructure width={33} img={global.domain+item.cursos_curso.img} title={item.cursos_curso.nombre}>
                    <a
                        href={`/consulta/${item.cursos_curso.id}/`}
                        className="mt-3 btn btn-block w-100 btn-success">Consultar aquí</a>
                </Cardstructure></Col>)}
            </Row>
            :
            <Alert variant={'info'}>No tiene cursos activos</Alert>
        }
    </div>
}

