import React, { useState, useEffect, useContext } from "react";
import { get } from "../Generals";
import Loader from '../Components/Loader';
import GeneralContext from "../utils/generalContext";
import { useParams } from "react-router";
import SidePanelHome from "../Components/Shared/SidePanelHome";

export const ContentDetail = () => {
    const [ content, setContent ]=useState(null)
    const { id } = useParams();
    const { global } = useContext(GeneralContext)
    useEffect(() => {
        get(
            global.domain+"v3-api/v3/content_detail.json",
            { id },
            setContent
        )
    }, [])
    
    return <>{ content == null ? <Loader/> : (
        <div>
            <SidePanelHome>
                <a className="mg-t-20 mg-b-0 tx-md tx-color-03 my-3" href='/'>Volver al login</a>
                <div className="bg-light p-4 rounded-3" style={{height: '60vh', overflowY: 'scroll'}}>
                    <div dangerouslySetInnerHTML={{__html: content.content}}></div>
                </div>
                
            </SidePanelHome>
        </div>
    )}</>

}

