import { Accordion } from 'react-bootstrap'
import React, { useState, useEffect, useContext } from "react"
import { post, sendTrack } from "../Generals"
import Loader from '../Components/Loader'
import GlobalIndex from "../Views/Globalindex"
import GeneralContext from "../utils/generalContext";

export const Faqs = () => {
    const [ faqs, setFaqs ]=useState(null)
    const [ page, setPage ]=useState(1)
    const [ pages ]=useState(1)
    const [ keyword ]=useState(null)
    const [ disabledval,setDisabledval ]=useState(true)
    const [ disabledvalnext,setDisabledvalnext ]=useState(true)
    const { user, global } = useContext(GeneralContext)
    const userid = user.user.user.id_alumno
    useEffect(() => {
        post(
            global.domain+"v3-api/v3/faqs_data.json",
            { keyword, page, userid },
            setFaqs
        )
        sendTrack(userid, window.location.pathname, window.location.hostname, global);
    }, [])

    useEffect(() => {
        faqs?.pages > 1 && setDisabledvalnext(false)
    }, [faqs])

    const changePage = (val) => {
        setFaqs(null)
        setPage(val)
        if(val == 1){
            setDisabledval(true)
        }else{
            setDisabledval(false)
        }
        if(val < pages){
            setDisabledvalnext(false)
        }else{
            setDisabledvalnext(true)
        }
    }

    const content = (item,index) => {
        return <li key={'faqs'+item.id} className={'list-group-item m-0 p-0'}>
            <div className="list-bodyss border-bottom w-100 p-4">
                <Accordion defaultActiveKey={0}>
                    <Accordion.Item eventKey={index}>
                        <Accordion.Header className={'bg-light'}>
                            <h5 className={'mb-0'}>{item.titulo}</h5>
                            <p className={'mb-0'}>{item.descriptiontranslated}</p>
                        </Accordion.Header>
                        <Accordion.Body>
                            {item.cursos_faqs.map((itemd,indexd) => {
                                return <div key={'subitem'+indexd+index}>
                                    <h6>{itemd.pregunta}</h6>
                                    <div dangerouslySetInnerHTML={{__html:itemd.respuestatranslated}}></div>
                                </div>
                            })}
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </li>
    }

    return <>{faqs== null ? <Loader/> : <GlobalIndex title={'Instructivos'} data={faqs.faqs} content={content} changePage={changePage} disabledval={disabledval} disabledvalnext={disabledvalnext} page={page} pages={pages}></GlobalIndex>}</>

}

